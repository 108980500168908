import React, { useState, useEffect, useRef } from "react";
import countryAndCity from "../services/countries.service";
import userService from "../services/users";
import "./../assets/styles/RegisterGamerProfile.css";
import S3 from "../services/Aws/s3";
import gameService from "../services/games.service";
import { connect, useDispatch } from "react-redux";
import {
  // getAllGames,
  getGamesPlayed,
  setError,
  setLoading,
  setSuccess,
  updateProfile,
} from "../actions";
import SelectInput from "./SelectInput";
import User from "./../assets/user2.jpg";
import { getData } from "../apis";
import AlertUser from "../Alerts/userAlerts";
import axios from "axios";

const RegisterGamerProfile = ({ user, Games, gamerTags }) => {
  const dispatch = useDispatch();
  const imgRef = useRef();
  const [gameVal, setGameVal] = useState({ game: "", tag: "" });
  const [gamePlayedArry, setGamPlayedArry] = useState(gamerTags);
  const [edit, setEdit] = useState({ index: -1, id: "" });

  const [FormUpdateUser, setFormUpdateUser] = useState({
    userName: user.userName,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    country: user.country,
    city: user.city,
    imgUser: user.urlPhoto,
  });

  const [Countrys, setCountrys] = useState([]);
  const [City, setCity] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [autosave, setAutoSave] = useState(false);
  const [items, setItems] = useState([]);
  const [gameSelected, setGameSelected] = useState({
    verifiable: "",
    game: "",
    id: "",
    tag: "",
    // platform: "",
  });

  const sendPictures3 = async (urlPhoto) => {
    dispatch(setLoading(true));
    try {
      if (urlPhoto !== null) {
        await S3.sendImage(urlPhoto, function (data) {
          if (data) {
            updateUserInformation(data.Location);
          }
        });
      } else {
        if (
          (FormUpdateUser.urlPhoto.length !== 0) &
          (FormUpdateUser.urlPhoto !== undefined)
        ) {
          await S3.sendImage(FormUpdateUser.urlPhoto, function (data) {
            if (data) {
              updateUserInformation(data.Location);
            }
          });
        }
      }
    } catch (error) {
      dispatch(setError("Something went wrong uploading the image."));
      console.log("error con s3", error);
    }
  };

  const getAllCountrys = async () => {
    dispatch(setLoading(true));
    const respgetAllCountrys = await countryAndCity.getCountry();
    if (respgetAllCountrys.success) {
      setCountrys(respgetAllCountrys.data);
      dispatch(setLoading(false));
    } else {
      dispatch(setError(respgetAllCountrys.data.message));
    }
  };

  const getAllCitys = async (country) => {
    dispatch(setLoading(true));
    const respgetAllCitys = await countryAndCity.getCity(country);
    if (respgetAllCitys.success) {
      setCity(respgetAllCitys.data);
      dispatch(setLoading(false));
    } else {
      dispatch(setError(respgetAllCitys.data.message));
    }
  };

  const updateUserInformation = async (urlImages3) => {
    dispatch(setLoading(true));
    const respupdateUserInformation = await userService.updateUser(
      user._id,
      FormUpdateUser,
      urlImages3 ? urlImages3 : ""
    );
    if (respupdateUserInformation.success) {
      dispatch(updateProfile(respupdateUserInformation.data.data));
      dispatch(setSuccess("Updated Player Profile"));
    } else {
      dispatch(setError(respupdateUserInformation.data.message));
    }
  };

  const processImage = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile !== undefined) {
      setFormUpdateUser({
        ...FormUpdateUser,
        urlPhoto: event.target.files[0],
      });

      const imageUrl = URL.createObjectURL(imageFile);
      setFileUrl(imageUrl);
    }
    if (autosave) {
      ChangePhoto(event.target.files[0]);
      setAutoSave(false);
    }
  };

  const ChangePhoto = (urlPhoto = null) => {
    if (FormUpdateUser.urlPhoto !== "") {
      sendPictures3(urlPhoto);
    }
  };

  //get list games
  const games = async () => {
    dispatch(setLoading(true));
    //  dispatch(getAllGames());
    dispatch(getGamesPlayed());
  };

  //update games played
  const updateGamesPlayed = async (id, index) => {
    dispatch(setLoading(true));
    const res = await gameService.updateGamesPlayed(
      id,
      gameVal.game,
      gameVal.tag
    );
    dispatch(setLoading(false));
    if (res.success) {
      dispatch(setSuccess("Gamer tags updated succesfully!"));
      //update array
      let newArry = gamePlayedArry.slice();
      newArry[index].game = gameVal.game;
      newArry[index].tag = gameVal.tag;
      setGamPlayedArry(newArry);
      setGameVal({ game: "", tag: "" });
      setEdit({ id: "", index: -1 });
    } else {
      dispatch(setError(res.data.message));
    }
  };

  useEffect(() => {
    games();
    getAllCountrys();
    const currentCountry = FormUpdateUser.country;
    if (currentCountry) {
      getAllCitys(currentCountry);
    }
  }, []);
  useEffect(() => {
    setGamPlayedArry(gamerTags);
  }, [gamerTags]);

  const addNewGameTag = async () => {
    let isRep = gamePlayedArry.filter((it) => it.game === gameVal.game);
    if (gameVal.game !== "" && gameVal.tag !== "") {
      if (isRep.length > 0) {
        dispatch(
          setError(
            "You already have a gamer tag for this game. Please choose other."
          )
        );
        return;
      }
      dispatch(setLoading(true));
      // Validate if the gametag exists in onetap
      const oneTap = items.find((item) => item.gameName === "One Tap");
      if (oneTap._id === gameVal.game) {
        try {
          const getUsername = await axios.get(
            `https://api.qorbiworld.com/sphere-one/user/${gameVal.tag}`
          );
          if (getUsername.data.email !== user.email) {
            dispatch(
              setError(
                "This gametag is associated with another email"
              )
            );
            return;
          }
        } catch (error) {
          dispatch(setError("This gametag does not exist"));
          return;
        }
      }
      const res = await gameService.addNewGamerTag(
        gameVal.game,
        gameVal.tag
      );
      if (!res.success) {
        dispatch(
          setError(
            "Something went wrong trying to add the tag. Please try again."
          )
        );
        return;
      }
      dispatch(getGamesPlayed());
      setGameVal({ game: "", tag: "" });
      dispatch(setLoading(false));
    }
  };

  // for verified games
  const addGameTag = async (userName) => {
    let isRep = gamePlayedArry.filter((it) => it.game === gameSelected.id);
    if (gameSelected.id !== "" && gameSelected.tag !== "") {
      if (isRep.length > 0) {
        dispatch(
          setError(
            "You already have a gamer tag for this game. Please choose other."
          )
        );
        return;
      }
      dispatch(setLoading(true));
      const res = await gameService.addNewGamerTag(
        gameSelected.id,
        userName !== "" & userName !== undefined ? userName : gameSelected.tag
      );
      if (!res.success) {
        dispatch(
          setError(
            "Something went wrong trying to add the tag. Please try again."
          )
        );
        return;
      }
      dispatch(getGamesPlayed());
      setGameSelected({ verifiable: "", game: "", id: "", tag: "", platform: "" });
      dispatch(setLoading(false));
    }
  };
  const removeGameTag = async (index, id) => {
    dispatch(setLoading(true));
    let res = await gameService.deleteGamerTag(id);
    if (res.success) {
      let newarray = gamePlayedArry.slice();
      newarray.splice(index, 1);
      setGamPlayedArry(newarray);
    } else {
      dispatch(setError(res.data.message));
    }
    dispatch(setLoading(false));
  };
  // allow user edit games tag
  const updateTagField = (value, index) => {
    let newarray = gamePlayedArry.slice();
    newarray[index].tag = value;
    setGamPlayedArry(newarray);
  };
  const getGameName = (id) => {
    if (items) {
      let res = items.filter((item) => item._id === id);
      if (res.length > 0) {
        return res[0].gameName;
      }
    }
  };
  // get player account data on the platform
  const getPlayerData = async () => {
    if (gameSelected.tag !== "" & gameSelected.game !== "") { //& gameSelected.platform !== ""
      const resp = await getData(`/v1/games/${gameSelected.tag}/${gameSelected.game}/,`)///${gameSelected.platform}
      if (resp.success !== false) {
        if (resp.data.message === "Player not found") {
          AlertUser.playerNotFound(resp.data.message);
        } else {
          switch (gameSelected.game) {
            case "Ev.io":
              addGameTag(resp.data.data ? resp.data.data.name[0].value : "");
              break;
            case "One Tap":
              addGameTag(resp.data.data ? resp.data.data.data.InfoResultPayload.AccountInfo.Username : "");
              break;
            default:
              addGameTag();
              break;
          }

        }
      } else {
        AlertUser.error(resp.data.message)
      }
    } else {
      AlertUser.fillFields();
    }
  }

  useEffect(() => {
    const getComments = async () => {
      const res = await getData(`/v1/games/get/all?page=1`);
      if (res) {
        const data1 = res.data.results;
        const res2 = await getData(`/v1/games/get/all?page=2`);
        if (res2) {
          const data2 = res2.data.results;
          const filterGames = [...data1, ...data2];
          const leakedGames = filterGames.filter(function (Games) {
            return Games.toCreateChallenges === true;
          });
          setItems(leakedGames);
        }
      }
    };

    getComments();
  }, []);

  useEffect(() => {
    if (window.location.host === "app.managames.io") {
      switch (gameVal.game) {
        case "632a6c944bccf1dae03e9e9a":
          const readData = async () => {
            const { data } = await getData(`/v1/games/Dota 2`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "dota2",
                id: "632a6c944bccf1dae03e9e9a",
                verifiable: data.message,
              });
            }
          };
          readData();
          break;
        case "632a6c944bccf1dae03e9e9b":
          const readData2 = async () => {
            const { data } = await getData(`/v1/games/Mobile Legends: Bang Bang`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "Mobile Legends: Bang Bang",
                id: "632a6c944bccf1dae03e9e9b",
                verifiable: data.message,
              });
            }
          };
          readData2();
          break;
        case "632a6c944bccf1dae03e9e9c":
          const readData3 = async () => {
            const { data } = await getData(`/v1/games/PUBG`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "pubg",
                id: "632a6c944bccf1dae03e9e9c",
                verifiable: data.message,
              });
            }
          };
          readData3();
          break;
        case "632a6c944bccf1dae03e9e9d":
          const readData4 = async () => {
            const { data } = await getData(`/v1/games/Fortnite`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "fortnite",
                id: "632a6c944bccf1dae03e9e9d",
                verifiable: data.message,
              });
            }
          };
          readData4();
          break;
        case "632a6c944bccf1dae03e9e9e":
          const readData5 = async () => {
            const { data } = await getData(`/v1/games/Call of Duty: Warzone`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "Call of Duty: Warzone",
                id: "632a6c944bccf1dae03e9e9e",
                verifiable: data.message,
              });
            }
          };
          readData5();
          break;
        case "63bf54fd80ab4bb381a2ec99":
          const readData6 = async () => {
            const { data } = await getData(`/v1/games/Ev.io`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "Ev.io",
                id: "63bf54fd80ab4bb381a2ec99",
                verifiable: data.message,
              });
            }
          };
          readData6();
          break;
        case "63bf54fd80ab4bb381a2ec9a":
          const readData7 = async () => {
            const { data } = await getData(`/v1/games/One Tap`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "One Tap",
                id: "63bf54fd80ab4bb381a2ec9a",
                verifiable: data.message,
              });
            }
          };
          readData7();
          break;
        default:
      }
    } else {
      switch (gameVal.game) {
        case "6345eae97729a91ff5e14f51":
          const readData = async () => {
            const { data } = await getData(`/v1/games/Dota 2`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "dota2",
                id: "6345eae97729a91ff5e14f51",
                verifiable: data.message,
              });
            }
          };
          readData();
          break;
        case "6345eae97729a91ff5e14f52":
          const readData2 = async () => {
            const { data } = await getData(`/v1/games/Mobile Legends: Bang Bang`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "Mobile Legends: Bang Bang",
                id: "6345eae97729a91ff5e14f52",
                verifiable: data.message,
              });
            }
          };
          readData2();
          break;
        case "6345eae97729a91ff5e14f53":
          const readData3 = async () => {
            const { data } = await getData(`/v1/games/PUBG`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "pubg",
                id: "6345eae97729a91ff5e14f53",
                verifiable: data.message,
              });
            }
          };
          readData3();
          break;
        case "6345eae97729a91ff5e14f54":
          const readData4 = async () => {
            const { data } = await getData(`/v1/games/Fortnite`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "fortnite",
                id: "6345eae97729a91ff5e14f54",
                verifiable: data.message,
              });
            }
          };
          readData4();
          break;
        case "6345eae97729a91ff5e14f55":
          const readData5 = async () => {
            const { data } = await getData(`/v1/games/Call of Duty: Warzone`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "Call of Duty: Warzone",
                id: "6345eae97729a91ff5e14f55",
                verifiable: data.message,
              });
            }
          };
          readData5();
          break;
        case "63c82dacade43c82776b809e":
          const readData6 = async () => {
            const { data } = await getData(`/v1/games/Ev.io`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "Ev.io",
                id: "63c82dacade43c82776b809e",
                verifiable: data.message,
              });
            }
          };
          readData6();
          break;
        case "63c82dacade43c82776b809f":
          const readData7 = async () => {
            const { data } = await getData(`/v1/games/One Tap`);
            if (data) {
              setGameSelected({
                ...gameSelected,
                game: "One Tap",
                id: "63c82dacade43c82776b809f",
                verifiable: data.message,
              });
            }
          };
          readData7();
          break;
        default:
      }
    }
  }, [gameVal.game]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="profile-img-container">
            <img
              src={
                fileUrl
                  ? fileUrl
                  : FormUpdateUser.imgUser
                    ? FormUpdateUser.imgUser
                    : User
              }
              alt="profile"
              onClick={() => imgRef.current.click()}
            />
            <input
              id="photo"
              type="file"
              style={{ display: "none" }}
              ref={imgRef}
              onChange={processImage}
              accept="image/*"
            />
            <button
              onClick={() => {
                if (fileUrl === null) {
                  imgRef.current.click();
                  setAutoSave(true);
                } else {
                  ChangePhoto();
                }
              }}
              className="btn-white-trans"
            >
              Update Profile Photo
            </button>
            {/* <button onClick={ChangePhoto} className="btn-white-trans btn-pink">
              Remove Profile Photo
            </button> */}
          </div>
          <div className="row">
            <div className="col-12 col-md-5">
              <SelectInput
                label="Game"
                value={gameVal.game}
                name="game"
                onChange={(val) => setGameVal({ ...gameVal, game: val })}
                options={items}
                placeholder="Game name"
                optionLabel="gameName"
                optionValue="_id"
              />
            </div>
            <div className="col-10 col-md-5">
              <div className="field-type2-cont">
                <label>Gametag</label>
                <>
                  {gameSelected.verifiable === "false" ||
                    gameSelected.verifiable === "" ? (
                    <div className="field-type2">
                      <input
                        value={gameVal.tag}
                        type="text"
                        placeholder="insert your gamertag"
                        onChange={({ target }) =>
                          setGameVal({ ...gameVal, tag: target.value })
                        }
                        onKeyPress={(event) => {
                          // update or add new tag
                          if (event.key === "Enter") {
                            if (gameVal.tag !== "" && gameVal.game !== "") {
                              if (edit.id !== "") {
                                updateGamesPlayed(edit.id, edit.index);
                              } else {
                                addNewGameTag();
                              }
                            }
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="field-type2">
                        <input
                          style={{ width: "100%" }}
                          placeholder="PlayerID"
                          onChange={({ target }) =>
                            setGameSelected({ ...gameSelected, tag: target.value })
                          }

                        />
                      </div>
                      <div className="field-type2 mt-2">
                        <input
                          disabled
                          value={gameSelected.game}
                          style={{ width: "100%" }}
                          placeholder="game Name"
                        />
                      </div>
                      {/* <div className="field-type2 mt-2">
                        <input
                          style={{ width: "100%" }}
                          placeholder="Platform"
                          onChange={({ target }) =>
                          setGameSelected({...gameSelected, platform: target.value})
                        }
                        />
                      </div>  */}
                    </>
                  )}
                </>
              </div>
            </div>
            <>
              {gameSelected.verifiable === "false" ||
                gameSelected.verifiable === "" ? <div
                  className="col-2 col-md-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                <i
                  className="fa fa-plus"
                  onClick={() => addNewGameTag()}
                  style={{ cursor: "pointer" }}
                />
              </div> : <div
                className="col-2 col-md-2"
                style={{ display: "flex", alignItems: "center" }}
              >
                <i
                  className="fa fa-plus"
                  onClick={() => getPlayerData()}
                  style={{ cursor: "pointer" }}
                />
              </div>}
            </>
          </div>
          <div className="gamestag">
            {items.length > 0
              ? gamePlayedArry.length > 0
                ? gamePlayedArry.map((item, index) => (
                  <div className="cont" key={index}>
                    <h3>{getGameName(item.game)}</h3>
                    <div className="pill">
                      <div
                        onClick={() => {
                          setEdit({ index, id: item._id });
                          setGameVal({ game: item.game, tag: item.tag });
                        }}
                      >
                        {item.tag}
                      </div>
                      <span
                        onClick={() => removeGameTag(index, item._id)}
                        className="close"
                      >
                        X
                      </span>
                    </div>
                  </div>
                  // <div className="row align-items-center" key={`game-${index}`}>
                  //   <div className="col-5">
                  //     <SelectInput
                  //       label="Game"
                  //       name={`game-${index}`}
                  //       value={item.game}
                  //       onChange={(val) => updateTagField(val, index)}
                  //       options={Games}
                  //       placeholder="Game name"
                  //       optionLabel="gameName"
                  //       optionValue="_id"
                  //     />
                  //   </div>
                  //   <div className="col-5">
                  //     <div className="field-type2-cont">
                  //       <label>Gametag</label>
                  //       <div className="field-type2">
                  //         <input
                  //           value={item.tag}
                  //           type="text"
                  //           placeholder="insert your gamertag"
                  //           onChange={({ target }) =>
                  //             updateTagField(target.value, index)
                  //           }
                  //           onKeyPress={(event) =>
                  //             event.key === "Enter" &&
                  //             item.game !== "" &&
                  //             item.tag !== ""
                  //               ? updateGamesPlayed(item._id, index)
                  //               : null
                  //           }
                  //         />
                  //       </div>
                  //     </div>
                  //   </div>
                  //   <div className="col-2">
                  //     <span
                  //       onClick={() => removeGameTag(index, item._id)}
                  //       style={{ cursor: "pointer" }}
                  //     >
                  //       <Trash />
                  //     </span>
                  //   </div>
                  // </div>
                ))
                : null
              : null}
          </div>
        </div>
        <div className="col-md-6">
          <div className="field-type2-cont">
            <label htmlFor="UserName">User Name</label>
            <div className="field-type2">
              <input
                disabled
                value={FormUpdateUser.userName}
                id="UserName"
                type="text"
                placeholder="Example User Name"
                className="ST-input-l"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    userName: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="email">Email</label>
            <div className="field-type2">
              <input
                disabled
                value={FormUpdateUser.email}
                id="email"
                type="text"
                placeholder="Email@Example.com"
                className="ST-input-l"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    email: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="first name">First Name</label>
            <div className="field-type2">
              <input
                value={FormUpdateUser.firstName}
                id="first name"
                type="text"
                placeholder="First Name"
                className="ST-input-l"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    firstName: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="last name">Last Name</label>
            <div className="field-type2">
              <input
                value={FormUpdateUser.lastName}
                id="last name"
                type="text"
                placeholder="Last Name"
                className="ST-input-l"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    lastName: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="country">Country</label>
            <div className="field-type2">
              <select
                id="country"
                value={FormUpdateUser.country}
                type="text"
                className="ST-l-Selecte"
                onChange={({ target }) => {
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    country: target.value,
                  });
                  getAllCitys(target.value);
                }}
              >
                <option key={""} value={""}></option>
                {Countrys.map((Country) => (
                  <option key={Country._id} value={Country._id}>
                    {Country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="city">State/Department</label>
            <div className="field-type2">
              <select
                id="city"
                value={FormUpdateUser.city}
                type="text"
                className="ST-l-Selecte"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    city: target.value,
                  })
                }
              >
                <option key={"4582946"} value={""}>
                  {FormUpdateUser.city}
                </option>
                {City.states !== undefined
                  ? City.states
                  .filter((city) => city.name !== FormUpdateUser.city)
                  .map((City) => (
                    <option key={City.id} value={City.name}>
                      {City.name}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </div>
          <button
            onClick={() => updateUserInformation()}
            className="btn-blue"
            style={{ marginBottom: "20px" }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    navbarSize: state.alerts.navbarSize,
    Games: state.data.games,
    gamerTags: state.data.gamerTags,
  };
};
export default connect(mapStateToProps)(RegisterGamerProfile);
